import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DrcImage } from '@driscollsinc/driscolls-react-components';
import BlackMedPng from '../Images/Black_Medium.png';
import BlackMedWebP from '../Images/Black_Medium.webp';
import BlueMedPng from '../Images/Blue_Medium.png';
import BlueMedWebP from '../Images/Blue_Medium.webp';
import RaspMedPng from '../Images/Rasp_Medium.png';
import RaspMedWebP from '../Images/Rasp_Medium.webp';
import StrawMedPng from '../Images/Straw_Medium.png';
import StrawMedWebP from '../Images/Straw_Medium.webp';
import LogoSmPng from '../Images/Logo_Small_Transparent.png';
import LogoSmWebP from '../Images/Logo_Small_Transparent.webp';

const styles = () => ({
    rootLoading: {
        textAlign: 'center'
    },
    notFloating: {
        width: 300,
        margin: 'auto'
    },
    hidden: {
        '& img': {
            visibility: 'hidden'
        }
    },
    '@keyframes bus-loading': {
        '0%': { transform: 'scale(0.7)' },
        '25%': { transform: 'scale(1)' },
        '50%': { transform: 'scale(0.7)' }
    }
});

class BusLoading extends React.Component {
    render() {
        const { text, classes, hidden, className, isFloating } = this.props;

        const isDarkTheme = window.matchMedia('(prefers-color-scheme: dark)').matches;
        const backgroundColor = isDarkTheme ? '#000' : '#FFF';

        return (
            <div className={`${classes.rootLoading} ${hidden ? classes.hidden : ''} ${isFloating ? '' : classes.notFloating} ${className}`}>
                <span
                    style={{
                        display: 'block',
                        fontSize: '1.25rem'
                    }}
                >
                    {text || 'Loading...'}
                </span>
                <span style={{ margin: '0 auto' }}>
                    <div className="main-wrapper">
                        <div className="bus-wrapper">
                            <div className="window-section">
                                <div className="window-glass">
                                    <div className="glass-inner">
                                        <div className="seats"></div>
                                    </div>
                                </div>
                                <div className="window-glass">
                                    <div className="glass-inner">
                                        <div className="seats first"></div>
                                        <div className="seats second"></div>
                                        <DrcImage src={BlackMedPng} webp={BlackMedWebP} alt="" />
                                    </div>
                                </div>
                                <div className="window-glass">
                                    <div className="glass-inner">
                                        <div className="seats first"></div>
                                        <div className="seats second"></div>
                                        <DrcImage src={BlueMedPng} webp={BlueMedWebP} alt="" />
                                    </div>
                                </div>
                                <div className="window-glass">
                                    <div className="glass-inner">
                                        <div className="seats first"></div>
                                        <div className="seats second"></div>
                                        <DrcImage src={RaspMedPng} webp={RaspMedWebP} alt="" />
                                    </div>
                                </div>
                                <div className="window-glass">
                                    <div className="glass-inner">
                                        <div className="seats first"></div>
                                        <div className="seats second"></div>
                                        <DrcImage src={StrawMedPng} webp={StrawMedWebP} alt="" />
                                    </div>
                                </div>
                                <div className="window-glass">
                                    <div className="glass-inner">
                                        <div className="seats first"></div>
                                        <div className="seats second"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="driver-section">
                                <div className="window-glass">
                                    <div className="glass-inner">
                                        <div className="handle"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="headlight-wrap">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="door-lower">
                                <div className="dot1"></div>
                                <div className="dot2"></div>
                            </div>
                            <div className="lower-shape" style={isFloating ? null : { borderBottomColor: backgroundColor }}></div>
                            <div className="sticker-wrap">
                                <DrcImage src={LogoSmPng} webp={LogoSmWebP} alt="" />
                            </div>
                            <div className="border-wrap">
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>
                            <div className="tyres-wrapper">
                                <div className="tyres-content" style={isFloating ? null : { background: backgroundColor }}>
                                    <div className="tyres">
                                        <div className="rim-section">
                                            <div className="stripe"></div>
                                        </div>
                                    </div>
                                </div>
                                <div className="tyres-content" style={isFloating ? null : { background: backgroundColor }}>
                                    <div className="tyres">
                                        <div className="rim-section">
                                            <div className="stripe"></div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="lights-wrap">
                                <div className="lights-1"></div>
                                <div className="lights lights-2"></div>
                                <div className="lights lights-3"></div>
                                <div className="lights lights-4"></div>
                                <div className="lights lights-5"></div>
                            </div>
                            <div className="square-1"></div>
                            <div className="square-2">
                                <div></div>
                            </div>
                            <div className="square-3">
                                <div></div>
                            </div>
                        </div>
                    </div>
                </span>
            </div>
        );
    }
}

export default withStyles(styles)(BusLoading);
