import {
    EXAMPLE_ACTION,
    SET_SELECTED_APP,
    SET_SELECTED_POOL,
    SET_APPLICATIONS,
    ADD_APPLICATION,
    SET_TRUSTED_ORIGINS,
    SET_GROUPS,
    SET_USERS,
    SET_GROUP_SEARCH,
    SET_APP_SEARCH_MAIN,
    SET_APP_SEARCH_DIALOG,
    SET_USER_SEARCH,
    SET_SELECTED_GROUP,
    SET_GROUP_ACCESS,
    SET_USER_POOLS,
    SET_POOL_DATA,
    SET_POOL_SEARCH,
    SET_PROFILE,
    SET_USER_APPLICATIONS,
    SET_GLOBAL_METADATA,
    SET_DATATYPES,
    SET_GROUP_ALLOW
} from '../actions/UserManagementActions';

const initialState = {
    allUsers: [],
    applications: [],
    allGroups: [],
    applicationOptions: [],
    trustedOrigins: [],
    allUsersOptions: [],
    allGroupOptions: [],
    groupIDtoName: {},
    groupAccessData: {},
    groupAccessMapping: [],
    userPools: [],
    poolUsers: [],
    poolGroups: [],
    poolGroupOptions: [],
    poolClients: [],
    poolClientOptions: [],
    allPoolOptions: [],
    userApplications: [],
    selectedGroupAllow: []
};

const UserManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXAMPLE_ACTION:
            return Object.assign({}, state, {
                example: action.payload
            });
        case SET_SELECTED_APP:
            return Object.assign({}, state, {
                selectedApp: action.payload
            });
        case SET_SELECTED_POOL:
            return Object.assign({}, state, {
                selectedPool: action.payload
            });
        case SET_SELECTED_GROUP:
            return Object.assign({}, state, {
                selectedGroup: action.payload.results
            });
        case SET_APPLICATIONS:
            var applications = action.payload.sort((a, b) => {
                const aLbl = a.label.toLowerCase();
                const bLbl = b.label.toLowerCase();

                if (aLbl < bLbl) {
                    return -1;
                }

                if (aLbl > bLbl) {
                    return 1;
                }

                return 0;
            });

            return Object.assign({}, state, {
                applications: applications,
                applicationOptions: applications.map((app) => ({
                    label: app.label,
                    value: app.id
                }))
            });
        case ADD_APPLICATION:
            return Object.assign({}, state, {
                applications: [...state.applications, action.payload]
            });
        case SET_TRUSTED_ORIGINS:
            var trustedOrigins = action.payload.sort((a, b) => {
                const aLbl = a.name.toLowerCase();
                const bLbl = b.name.toLowerCase();

                if (aLbl < bLbl) {
                    return -1;
                }

                if (aLbl > bLbl) {
                    return 1;
                }

                return 0;
            });

            return Object.assign({}, state, {
                trustedOrigins: trustedOrigins
            });
        case SET_GROUPS: {
            let groupIDtoNameMap = {};
            return Object.assign({}, state, {
                allGroups: action.payload,
                allGroupOptions: action.payload.map((group) => {
                    groupIDtoNameMap[group.id] = group.profile.name;
                    return {
                        label: group.profile.name,
                        value: group.id,
                        type: group.type
                    };
                }),
                allGroupNonAdOptions: action.payload
                    .filter((group) => group.type !== 'APP_GROUP')
                    .map((group) => {
                        groupIDtoNameMap[group.id] = group.profile.name;
                        return {
                            label: group.profile.name,
                            value: group.id,
                            type: group.type
                        };
                    }),
                groupIDtoName: groupIDtoNameMap
            });
        }
        case SET_USERS:
            var allUsersOptions = [];
            var userOptionsWithEmail = [];
            action.payload.forEach((usr) => {
                allUsersOptions.push({
                    label: `${usr.profile.firstName} ${usr.profile.lastName}`,
                    value: usr.id
                });
                userOptionsWithEmail.push({
                    label: `${usr.profile.firstName} ${usr.profile.lastName} (${usr.profile.email})`,
                    value: usr.id
                });
            });
            return Object.assign({}, state, {
                allUsers: action.payload,
                allUsersOptions,
                userOptionsWithEmail
            });
        case SET_GROUP_SEARCH:
            return Object.assign({}, state, {
                searchedGroup: action.payload
            });
        case SET_APP_SEARCH_MAIN:
            return Object.assign({}, state, {
                searchedAppMain: action.payload
            });
        case SET_APP_SEARCH_DIALOG:
            return Object.assign({}, state, {
                searchedAppDialog: action.payload
            });
        case SET_USER_SEARCH:
            return Object.assign({}, state, {
                searchedUser: action.payload
            });
        case SET_POOL_SEARCH:
            return Object.assign({}, state, {
                searchedPool: action.payload
            });
        case SET_GROUP_ACCESS:
            var groupAccessMapping = action.payload.results;
            return Object.assign({}, state, {
                groupAccessData: action.payload,
                groupAccessMapping
            });
        case SET_USER_POOLS:
            return Object.assign({}, state, {
                userPools: action.payload
            });
        case SET_POOL_DATA:
            var allPoolOptions = action.payload.userPools.results.map((pool) => {
                return { label: pool.Name, value: pool.Id };
            });
            var poolClientOptions = action.payload.poolClients.results.map((poolClient) => {
                return { label: poolClient.ClientName, value: poolClient.ClientId, ...poolClient, isCognito: true };
            });
            var formattedCognitoUsers = action.payload.poolUsers.results.map((user) => {
                var attributes = {};
                user.Attributes.map((attribute) => {
                    attributes[attribute.Name] = attribute.Value;
                });
                return {
                    ...user,
                    Attributes: attributes
                };
            });
            var poolGroupOptions = action.payload.poolGroups.results.map((opt) => {
                return { label: opt.GroupName, value: opt.GroupName, type: 'COGNITO', ...opt };
            });
            return Object.assign({}, state, {
                userPools: action.payload.userPools.results,
                poolUsers: formattedCognitoUsers,
                poolGroups: action.payload.poolGroups.results,
                poolGroupOptions,
                poolClients: action.payload.poolClients.results,
                poolClientOptions,
                allPoolOptions
            });
        case SET_PROFILE:
            return Object.assign({}, state, {
                profile: action.payload
            });
        case SET_USER_APPLICATIONS:
            var userApplications = action.payload.sort((a, b) => {
                const aLbl = a.label.toLowerCase();
                const bLbl = b.label.toLowerCase();

                if (aLbl < bLbl) {
                    return -1;
                }

                if (aLbl > bLbl) {
                    return 1;
                }

                return 0;
            });

            return Object.assign({}, state, {
                userApplications: userApplications,
                userApplicationsOptions: userApplications.map((app) => ({
                    label: app.label,
                    value: app.id
                }))
            });
        case SET_GLOBAL_METADATA:
            return Object.assign({}, state, {
                globalMetadata: action.payload
            });
        case SET_DATATYPES:
            return Object.assign({}, state, {
                dataTypes: action.payload || [],
                dataTypeOptions: (action.payload || []).map((item) => ({ label: item.datatypeName, value: item.datatypeName }))
            });
        case SET_GROUP_ALLOW:
            return Object.assign({}, state, {
                selectedGroupAllow: action.payload
            });
        default:
            return state;
    }
};

export default UserManagementReducer;
