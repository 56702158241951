import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Translate } from 'react-localize-redux';
import { DrcMain, DrcLoading } from '@driscollsinc/driscolls-react-components';
import * as rdd from 'react-device-detect';
import DeviceCard from '../components/DeviceCard';

function UrlShortener() {
    const { urlId } = useParams();

    const language = useSelector((state) => {
        return { activeLanguage: state.localize.languages.find((lang) => lang.active) };
    });

    const [urlRedirect, setUrlRedirect] = useState({ isReady: false, isError: false });
    const [content, setContent] = useState(null);

    useEffect(() => {
        switch (urlId) {
            case 'PBA':
                setUrlRedirect({
                    isReady: true,
                    isError: false,
                    name: { en: 'Pallet Builder', es: 'Armado de Tarimas' },
                    description: {
                        en: 'Link to Pallet Builder for different device types.',
                        es: 'Enlace a Armado de Tarimas para diferentes tipos de dispositivos.'
                    },
                    android: {
                        en: 'https://play.google.com/store/apps/details?id=com.driscollsinc.palletbuilder',
                        es: 'https://play.google.com/store/apps/details?id=com.driscollsinc.palletbuilder&hl=es&gl=US'
                    },
                    ios: {
                        en: 'https://apps.apple.com/us/app/pallet-builder/id1553994834',
                        es: 'https://apps.apple.com/mx/app/pallet-builder/id1553994834'
                    },
                    windows: '',
                    mac: '',
                    default: ''
                });
                break;
            case 'GPA':
                setUrlRedirect({
                    isReady: true,
                    isError: false,
                    name: 'Grower Portal',
                    description: {
                        en: 'Link to Grower Portal Application for different device types.',
                        es: 'Enlace a Grower Portal para diferentes tipos de dispositivos.'
                    },
                    android: {
                        en: 'https://play.google.com/store/apps/details?id=com.driscolls',
                        es: 'https://play.google.com/store/apps/details?id=com.driscolls&hl=es&gl=US'
                    },
                    ios: {
                        en: 'https://apps.apple.com/us/app/grower-portal/id1472773270',
                        es: 'https://apps.apple.com/mx/app/grower-portal/id1472773270'
                    },
                    windows: '',
                    mac: '',
                    default: ''
                });
                break;
            default:
                setUrlRedirect({ isReady: true, isError: true });
        }
    }, []);

    const buildContent = () => {
        let displayContent = [];

        if (isValid(urlRedirect.android)) {
            displayContent.push(<DeviceCard key="android" type="android" link={urlRedirect.android} />);
        }

        if (isValid(urlRedirect.ios)) {
            displayContent.push(<DeviceCard key="ios" type="ios" link={urlRedirect.ios} />);
        }

        if (isValid(urlRedirect.windows)) {
            displayContent.push(<DeviceCard key="windows" type="windows" link={urlRedirect.windows} />);
        }

        if (isValid(urlRedirect.mac)) {
            displayContent.push(<DeviceCard key="mac" type="mac" link={urlRedirect.mac} />);
        }

        if (displayContent.length <= 0 && isValid(urlRedirect.default)) {
            displayContent.push(<DeviceCard key="default" type="default" link={urlRedirect.default} />);
        }

        setContent(<div className="row">{displayContent.map((c) => c)}</div>);
    };

    const isValid = (obj) => {
        if (typeof obj === 'string' && obj.length > 0) {
            return true;
        }

        if (typeof obj === 'object') {
            let keys = Object.keys(obj);

            return keys.includes('en') || keys.includes('es') || keys.includes('fr') || keys.includes('zh-CN');
        }

        return false;
    };

    const getValue = (obj, translate) => {
        if (typeof obj === 'string') {
            return obj;
        }

        let isoLanguage = translate?.activeLanguage?.code || 'en';

        if (obj[isoLanguage] && obj[isoLanguage].length > 0) {
            return obj[isoLanguage] || '';
        }

        if (obj['en'] && obj['en'].length > 0) {
            return obj['en'] || '';
        }

        return '';
    };

    useEffect(() => {
        if (!urlRedirect.isReady || urlRedirect.isError) {
            if (content !== null) {
                setContent(null);
            }

            return;
        }

        if (isValid(urlRedirect.android) && rdd.isAndroid) {
            window.location = getValue(urlRedirect.android, language);
        } else if (isValid(urlRedirect.ios) && rdd.isIOS) {
            window.location = getValue(urlRedirect.ios, language);
        } else if (isValid(urlRedirect.windows) && rdd.isWindows) {
            window.location = getValue(urlRedirect.windows, language);
        } else if (isValid(urlRedirect.mac) && rdd.isMacOs) {
            window.location = getValue(urlRedirect.mac, language);
        } else {
            if (isValid(urlRedirect.default)) {
                window.location = getValue(urlRedirect.default, language);
            } else {
                buildContent();
            }
        }
    }, [urlRedirect]);

    return (
        <DrcMain maxWidth={600}>
            <Translate>
                {(translate) => (
                    <div>
                        <h1 style={{ textAlign: 'center' }}>
                            {!urlRedirect.isReady ? (
                                <Translate id="links.fetchingRedirect" />
                            ) : urlRedirect.isError ? (
                                <Translate id="links.redirectDoesNotExist" />
                            ) : !!content ? (
                                getValue(urlRedirect.name, translate)
                            ) : (
                                <Translate id="links.redirectingNow" />
                            )}
                        </h1>
                        {urlRedirect.isReady ? (
                            <div>
                                <p style={{ textAlign: 'center', fontSize: '1.2rem' }}>{getValue(urlRedirect.description, translate)}</p>
                                {content}
                            </div>
                        ) : (
                            <DrcLoading />
                        )}
                    </div>
                )}
            </Translate>
        </DrcMain>
    );
}

export default UrlShortener;
