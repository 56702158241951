import { GS_TRIGGER } from '../actions/TriggersActions';

const initialState = {
    // adding poolweeks of 2021, In POC2: need to update on getting poolweeks dynamically
    gsTriggerPoolWeeks: {
        year: 2021,
        poolWeeks: [
            { label: '2101-Jan', value: 2101 },
            { label: '2102-Jan', value: 2102 },
            { label: '2103-Jan', value: 2103 },
            { label: '2104-Jan', value: 2104 },
            { label: '2105-Jan', value: 2105 },
            { label: '2106-Feb', value: 2106 },
            { label: '2106-Jan', value: 2106 },
            { label: '2107-Feb', value: 2107 },
            { label: '2108-Feb', value: 2108 },
            { label: '2109-Feb', value: 2109 },
            { label: '2110-Mar', value: 2110 },
            { label: '2110-Feb', value: 2110 },
            { label: '2111-Mar', value: 2111 },
            { label: '2112-Mar', value: 2112 },
            { label: '2113-Mar', value: 2113 },
            { label: '2114-Apr', value: 2114 },
            { label: '2114-Mar', value: 2114 },
            { label: '2115-Apr', value: 2115 },
            { label: '2116-Apr', value: 2116 },
            { label: '2117-Apr', value: 2117 },
            { label: '2118-Apr', value: 2118 },
            { label: '2118-May', value: 2118 },
            { label: '2119-May', value: 2119 },
            { label: '2120-May', value: 2120 },
            { label: '2121-May', value: 2121 },
            { label: '2122-May', value: 2122 },
            { label: '2123-May', value: 2123 },
            { label: '2123-Jun', value: 2123 },
            { label: '2124-Jun', value: 2124 },
            { label: '2125-Jun', value: 2125 },
            { label: '2126-Jun', value: 2126 },
            { label: '2127-Jun', value: 2127 },
            { label: '2127-Jul', value: 2127 },
            { label: '2128-Jul', value: 2128 },
            { label: '2129-Jul', value: 2129 },
            { label: '2130-Jul', value: 2130 },
            { label: '2131-Jul', value: 2131 },
            { label: '2132-Aug', value: 2132 },
            { label: '2133-Aug', value: 2133 },
            { label: '2134-Aug', value: 2134 },
            { label: '2135-Aug', value: 2135 },
            { label: '2136-Aug', value: 2136 },
            { label: '2136-Sep', value: 2136 },
            { label: '2137-Sep', value: 2137 },
            { label: '2138-Sep', value: 2138 },
            { label: '2139-Sep', value: 2139 },
            { label: '2140-Oct', value: 2140 },
            { label: '2140-Sep', value: 2140 },
            { label: '2141-Oct', value: 2141 },
            { label: '2142-Oct', value: 2142 },
            { label: '2143-Oct', value: 2143 },
            { label: '2144-Oct', value: 2144 },
            { label: '2145-Oct', value: 2145 },
            { label: '2145-Nov', value: 2145 },
            { label: '2146-Nov', value: 2146 },
            { label: '2147-Nov', value: 2147 },
            { label: '2148-Nov', value: 2148 },
            { label: '2149-Dec', value: 2149 },
            { label: '2149-Nov', value: 2149 },
            { label: '2150-Dec', value: 2150 },
            { label: '2151-Dec', value: 2151 },
            { label: '2152-Dec', value: 2152 },
            { label: '2210-Dec', value: 2201 }
        ]
    },
    regions: [
        { label: 'DEMEA', value: 'demea' },
        { label: 'DOC', value: 'doc' },
        { label: 'DOTA', value: 'dota' }
    ]
};

const TriggersReducer = (state = initialState, action) => {
    switch (action.type) {
        case GS_TRIGGER:
            return Object.assign({}, state, {
                gsTrigger: action.payload
            });
        default:
            return state;
    }
};

export default TriggersReducer;
