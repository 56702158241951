// [English, Spanish, Chinese, French]

const APP_TRANSLATIONS = {
    tools: {
        user: ['User Management', 'Gestión de Usuarios', '用户管理', 'Gestion des Utilisateurs'],
        folder: ['Folder Management', 'Gestión de Carpetas', '资料夹管理', 'Gestion des Dossiers'],
        styleGuide: ['Style Guide', 'Guía de Estilo', '时尚指南', 'Guide de Style'],
        developerLearning: ['Developer Learning', 'Aprendizaje del Desarrollador', '开发者学习', 'Apprentissage des Développeurs'],
        configuration: ['Configuration Management', 'Gestión de la Configuración', '配置管理', 'Gestion de la Configuration'],
        resources: ['Resources', 'Recursos', '资源资源', 'Ressources'],
        releaseManagement: ['Release Management', 'Release Management', 'Release Management', 'Release Management'],
        documentCreation: ['Document Creation', 'Creación de documentos', '文件製作', 'Création de documents'],
        resourceAllocation: ['Resource Allocation', 'Asignación de recursos', '资源分配', 'Allocation de ressources'],
        urlShortener: ['URL Shortener', 'Acortador de URL', 'URL缩短器', "Raccourcissement d'URL"],
        triggers: ['Triggers', 'Disparadores', '触发器', 'Déclencheurs'],
        disasterRecovery: ['Disaster Recovery', 'Recuperación de Desastres', '灾难恢复', 'Récupération des catastrophes'],
        daxsupport: ['Dax Support', 'soporte dax', '達克斯支持', 'support Dax']
    },
    survey: {
        survey: ['Survey', 'Encuesta', '民意调查', 'Enquête'],
        tagline: [
            'Please let us know how much you enjoyed our ',
            'Háganos saber cuánto disfrutó de nuestras',
            '请告诉我们您有多喜欢我们的',
            "S'il vous plaît laissez-nous savoir combien vous avez apprécié nos "
        ],
        overallRating: ['Overall Rating', 'Calificación general', '总体评价', 'Note globale'],
        lowest: ['Lowest', 'Más bajo', '最低', 'Le plus bas'],
        highest: ['Highest', 'Mas alta', '最高', 'Plus haut'],
        recommend: [
            'Would you recommend our berries?',
            '¿Recomendarías nuestras berries?',
            '你会推荐我们的浆果吗？',
            'Recommanderiez-vous nos baies?'
        ],
        moreToAdd: ['Anything more to add?', '¿Algo más que agregar?', '还有什么要补充的吗？', 'Quelque chose de plus à ajouter ?']
    },
    berries: {
        black: ['Blackberry', 'Mora', '黑莓', 'la mûre'],
        blacks: ['Blackberries', 'Moras', '黑莓', 'Mûres'],
        blue: ['Blueberry', 'Arándano', '蓝莓', 'Myrtille'],
        blues: ['Blueberries', 'Arándanos', '蓝莓', 'Myrtilles'],
        rasp: ['Raspberry', 'Framboise', '覆盆子', 'Framboise'],
        rasps: ['Raspberries', 'Frambuesas', '山莓', 'Framboises'],
        straw: ['Strawberry', 'Fresa', '草莓', 'Fraise'],
        straws: ['Strawberries', 'Fresas', '草莓', 'Des fraises']
    },
    buttonTempSubmit: ['Submit', 'Enviar', '提交', 'Soumettre']
};

export default APP_TRANSLATIONS;
