export const SET_FOLDER_DATA = 'SET_FOLDER_DATA';
export const setFolderData = (data) => ({
    type: SET_FOLDER_DATA,
    payload: data
});

export const SET_FILE_TYPES = 'SET_FILE_TYPES';
export const setFileTypes = (data) => ({
    type: SET_FILE_TYPES,
    payload: data
});

export const ADD_BUCKET_OPTION = 'ADD_BUCKET_OPTION';
export const addBucketOption = (option) => ({
    type: ADD_BUCKET_OPTION,
    payload: option
});

export const SET_FOLDER_MAPPINGS = 'SET_FOLDER_MAPPINGS';
export const setFolderMappings = (data) => ({
    type: SET_FOLDER_MAPPINGS,
    payload: data
});

export const SET_BUCKETS = 'SET_BUCKETS';
export const setBuckets = (data) => ({
    type: SET_BUCKETS,
    payload: data
});
