export const SET_COMMON_DIALOG = 'SET_COMMON_DIALOG';
export const HIDE_ERROR_DIALOG = 'HIDE_ERROR_DIALOG';

export const LOGOUT = 'LOGOUT';

export const SHOW_LOADING_SCREEN = 'SHOW_LOADING_SCREEN';
export const HIDE_LOADING_SCREEN = 'HIDE_LOADING_SCREEN';

export const SET_USER_ROLE_NAME = 'SET_USER_ROLE_NAME';

// Master Actions
export const SET_PAGE_TITLE = 'SET_PAGE_TITLE';
export const setPageTitleAction = (pageTitle) => ({
    type: SET_PAGE_TITLE,
    payload: pageTitle
});

export const ADD_INFO = 'ADD_INFO';
export const addInfo = (info) => ({
    type: ADD_INFO,
    payload: info
});

// Modal Reducers
export const setCommonDialogAction = (title, errors, isMessage) => ({
    type: SET_COMMON_DIALOG,
    payload: { title, errors, isMessage }
});

export const hideCommonDialogAction = () => ({
    type: HIDE_ERROR_DIALOG,
    payload: {}
});

export const logoutAction = () => ({
    type: LOGOUT,
    payload: {}
});

export const showLoadingScreenAction = (loadingMessage) => ({
    type: SHOW_LOADING_SCREEN,
    payload: loadingMessage
});

export const hideLoadingScreenAction = () => ({
    type: HIDE_LOADING_SCREEN,
    payload: {}
});

export const setUserRoleName = (value) => ({
    type: SET_USER_ROLE_NAME,
    payload: value
});

export const SHOW_TOAST = 'SHOW_TOAST';
export const showToast = (toastMessage, isSuccess) => ({
    type: SHOW_TOAST,
    payload: { toastMessage, isSuccess }
});
