import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withLocalize, Translate } from 'react-localize-redux';
import { DrcButton } from '@driscollsinc/driscolls-react-components';
import DeviceUnknownIcon from '@material-ui/icons/DeviceUnknown';
import AndroidIcon from '@material-ui/icons/Android';
import IosIcon from '@material-ui/icons/PhoneIphone';
import WindowsIcon from '@material-ui/icons/DesktopWindows';
import MacIcon from '@material-ui/icons/DesktopMac';

var styles = () => ({
    button: {
        width: '100%',
        marginLeft: 0
    },
    iconWrapper: {
        '& svg': {
            fontSize: '5rem'
        },
        marginRight: '2rem'
    },
    text: {
        fontSize: '2rem',
        fontFamily: 'BlogScript',
        marginTop: 10,
        textTransform: 'none'
    }
});

class DeviceCard extends React.Component {
    getValue = (obj) => {
        if (typeof obj === 'string') {
            return obj;
        }

        let isoLanguage = this.props.activeLanguage?.code || 'en';

        if (obj[isoLanguage] && obj[isoLanguage].length > 0) {
            return obj[isoLanguage] || '';
        }

        if (obj['en'] && obj['en'].length > 0) {
            return obj['en'] || '';
        }

        return '';
    };

    render() {
        var { classes, type, link } = this.props;

        let icon = <DeviceUnknownIcon />;
        let deviceName = type;
        let translatedLink = this.getValue(link);

        switch (type) {
            case 'android':
                icon = <AndroidIcon />;
                deviceName = <Translate id="devices.android" />;
                break;
            case 'ios':
                icon = <IosIcon />;
                deviceName = <Translate id="devices.ios" />;
                break;
            case 'windows':
                icon = <WindowsIcon />;
                deviceName = <Translate id="devices.windows" />;
                break;
            case 'mac':
                icon = <MacIcon />;
                deviceName = <Translate id="devices.mac" />;
                break;
            case 'default':
                icon = <WindowsIcon />;
                deviceName = <Translate id="links.go" />;
                break;
        }

        return (
            <div className="col-xs-12 col-sm-6">
                <DrcButton className={classes.button} isSecondary href={translatedLink} noStyle>
                    <div className={classes.iconWrapper}>{icon}</div>
                    <span className={classes.text}>{deviceName}</span>
                </DrcButton>
            </div>
        );
    }
}

export default withStyles(styles)(withLocalize(DeviceCard));
