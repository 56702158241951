import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setPageTitleAction } from '../actions/actions';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { DrcMain, DrcImage } from '@driscollsinc/driscolls-react-components';
import Robot from '../components/Robot';
import ServiceTools from '../components/ServiceTools';
import BackgroundPng from '../Images/Bowl_Mixed_3.png';
import BackgroundWebP from '../Images/Bowl_Mixed_3.webp';

const pageTitle = 'Tools';

const styles = () => ({
    image: {
        position: 'absolute',
        height: '50vh',
        width: '100vw',
        top: 0,
        left: 0,
        objectFit: 'cover',
        zIndex: -5,
        boxShadow: '0px 3px 15px 0px #818181'
    },
    description: {
        width: '100%',
        fontSize: '2rem',
        fontWeight: 'bold',
        textAlign: 'center'
    }
});

class ServiceToolsPage extends React.Component {
    componentDidMount() {
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }
    }

    render() {
        var { isMasterDataInitialized, classes } = this.props;

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        return (
            <DrcMain transparent>
                <DrcImage src={BackgroundPng} webp={BackgroundWebP} className={classes.image} alt="" />
                <p className={classes.description}>Driscoll's BUS Management Tools</p>
                <ServiceTools />
                <Robot peekaboo={true} />
            </DrcMain>
        );
    }
}

function mapStateToProps(state) {
    return {
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ServiceToolsPage));
