import { combineReducers } from 'redux';
import { localizeReducer } from 'react-localize-redux';
import masterReducer from './MasterReducer';
import UserManagementReducer from '../tools/UserManagement/reducers/UserManagementReducer';
import FolderManagementReducer from '../tools/FolderManagement/reducers/FolderManagementReducer';
import ReleaseManagementReducer from '../tools/ReleaseNotes/reducers/ReleaseManagementReducer';
import RequestReducer from '../tools/Resources/reducers/RequestsReducer';
import TriggerReducer from '../tools/Triggers/reducers/TriggersReducer';

import { SHOW_LOADING_SCREEN, HIDE_LOADING_SCREEN, SET_COMMON_DIALOG, HIDE_ERROR_DIALOG, SET_PAGE_TITLE, SHOW_TOAST } from '../actions/actions';

const initialState = {
    showLoadingScreen: false,
    pageTitle: '',
    commonDialog: {
        show: false,
        title: 'Unknown Error',
        content: 'Whazzzuuppp'
    }
};

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case SET_PAGE_TITLE:
            return Object.assign({}, state, {
                ...state,
                pageTitle: (action.payload || '').length > 0 ? 'BUS ' + action.payload : 'Business Utility Services'
            });
        case SHOW_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: true,
                loadingScreenMessage: action.payload
            };
        case HIDE_LOADING_SCREEN:
            return {
                ...state,
                showLoadingScreen: false,
                loadingScreenMessage: ''
            };
        case SET_COMMON_DIALOG:
            return {
                ...state,
                commonDialog: {
                    show: true,
                    title: action.payload.title,
                    content: action.payload.errors,
                    isMessage: !!action.payload.isMessage
                }
            };
        case HIDE_ERROR_DIALOG:
            return {
                ...state,
                commonDialog: {
                    show: false,
                    title: '',
                    content: ''
                }
            };
        case SHOW_TOAST:
            return {
                ...state,
                toastMessage: action.payload.toastMessage,
                isSuccess: action.payload.isSuccess
            };
        default:
            break;
    }

    return state;
}

export default combineReducers({
    rootReducer,
    localize: localizeReducer,
    masterReducer,
    UserManagementReducer,
    FolderManagementReducer,
    ReleaseManagementReducer,
    RequestReducer,
    TriggerReducer
});
