import { SET_FOLDER_DATA, SET_FILE_TYPES, ADD_BUCKET_OPTION, SET_FOLDER_MAPPINGS, SET_BUCKETS } from '../actions/FolderManagementActions';

const initialState = {
    folders: {},
    folderOptions: [],
    fileTypes: {},
    bucketMappings: {},
    bucketMapObject: [],
    fileTypeOptions: [],
    bucketNameOptions: [],
    mimeTypeMapping: {},
    buckets: [],
    bucketOptions: []
};

const FolderManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FOLDER_DATA:
            var folders = action.payload;
            var tempBucketNameOptions = [];
            var bucketNamesAdded = [];
            folders.results = folders.results.sort((a, b) => {
                if (a.folderName < b.folderName) {
                    return -1;
                }

                if (a.folderName > b.folderName) {
                    return 1;
                }

                return 0;
            });

            const folderOptions = folders.results.map((folderItem) => {
                if (!bucketNamesAdded.includes(folderItem.bucketName)) {
                    tempBucketNameOptions.push({ label: folderItem.bucketName, value: folderItem.bucketName });
                    bucketNamesAdded.push(folderItem.bucketName);
                }
                return { label: folderItem.folderName, value: folderItem.folderId, bucketName: folderItem.bucketName };
            });

            return Object.assign({}, state, {
                folders,
                folderOptions,
                bucketNameOptions: tempBucketNameOptions
            });
        case SET_FILE_TYPES:
            var fileTypes = action.payload;
            var mimeTypeMapping = {};
            fileTypes.results = fileTypes.results.sort((a, b) => {
                if (a.fileExtension < b.fileExtension) {
                    return -1;
                }

                if (a.fileExtension > b.fileExtension) {
                    return 1;
                }

                return 0;
            });

            const fileTypeOptions = fileTypes.results.map((fileType) => {
                mimeTypeMapping[fileType.fileExtension] = fileType.fileMimeType;
                return { label: fileType.fileExtension, value: fileType.fileTypeId };
            });

            return Object.assign({}, state, {
                fileTypes,
                fileTypeOptions,
                mimeTypeMapping
            });
        case ADD_BUCKET_OPTION:
            var bucketNameOptions = [...state.bucketNameOptions, action.payload];
            return Object.assign({}, state, {
                bucketNameOptions
            });
        case SET_FOLDER_MAPPINGS:
            var bucketMappings = action.payload;
            var registeredBucketOptions = [];
            var bucketMapObject = {};
            bucketMappings.results.forEach((bucket) => {
                registeredBucketOptions.push({ label: bucket.bucketName, value: bucket.bucketName});
                bucketMapObject[bucket.bucketName] = {
                    isShared: bucket.isShared,
                    applicationIds: bucket.applicationIds,
                    bucketMappingId: bucket.bucketMappingId
                };
            });
            return Object.assign({}, state, {
                bucketMappings,
                bucketMapObject,
                registeredBucketOptions
            });
        case SET_BUCKETS:
            var buckets = action.payload;

            var bucketOptions = buckets.results.map((bucket) => {
                return {
                    label: bucket.Name,
                    value: bucket.Name
                };
            });
            return Object.assign({}, state, {
                buckets,
                bucketOptions
            });
        default:
            return state;
    }
};

export default FolderManagementReducer;
