import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { DrcButton, DrcMediaQueries } from '@driscollsinc/driscolls-react-components';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';

const BACKGROUND_HOVER_BRIGHTNESS = -10;
const BACKGROUND_DARK_BRIGHTNESS = -5;

var styles = (theme) => ({
    serviceTool: {
        display: 'inline-block',
        width: '100% !important',
        height: 147,
        margin: '10px !important',
        borderRadius: '15px !important',
        padding: '0 10px',
        color: DuThemeUtilities.DefaultColors.text.black.main + ' !important',
        backgroundColor: DuThemeUtilities.DefaultColors.text.white.main + ' !important',
        borderColor: DuThemeUtilities.DefaultColors.text.black.main + ' !important',
        '&:hover': {
            backgroundColor:
                DuThemeUtilities.UpdateHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.text.white.main), BACKGROUND_HOVER_BRIGHTNESS) +
                ' !important'
        },
        [theme.darkTheme]: {
            color: DuThemeUtilities.DefaultColors.text.white.main + ' !important',
            backgroundColor:
                DuThemeUtilities.UpdateHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.text.black.main), BACKGROUND_DARK_BRIGHTNESS) +
                ' !important',
            borderColor: DuThemeUtilities.DefaultColors.text.white.main + ' !important',
            '&:hover': {
                backgroundColor:
                    DuThemeUtilities.UpdateHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.text.black.main), BACKGROUND_HOVER_BRIGHTNESS) +
                    ' !important'
            }
        },
        '& > span': {
            height: '100%'
        },
        ['@media ' + DrcMediaQueries.mobileL]: {
            height: 112
        }
    },
    icon: {
        '& svg': {
            fontSize: 60,
            margin: '10px auto',
            display: 'block',
            filter: 'drop-shadow(3px 3px 2px rgba(0, 0, 0, .7))',
            color: DuThemeUtilities.DefaultColors.primary.green + ' !important',
            ['@media ' + DrcMediaQueries.mobileL]: {
                fontSize: 40
            }
        }
    },
    textWrapper: {
        textAlign: 'center',
        padding: '0 4px'
    },
    text: {
        fontSize: '1.25rem',
        ['@media ' + DrcMediaQueries.mobileL]: {
            fontSize: '1rem',
            fontFamily: 'inherit'
        }
    }
});

class ServiceTool extends React.Component {
    render() {
        var { classes, children, text, to, onClick } = this.props;

        return (
            <div className="col-xs-6 col-sm-4 col-md-3">
                <DrcButton className={classes.serviceTool} isSecondary to={to} onClick={onClick}>
                    <div className={classes.icon}>{children}</div>
                    <div className={classes.textWrapper}>
                        <span className={classes.text}>{text}</span>
                    </div>
                </DrcButton>
            </div>
        );
    }
}

export default withStyles(styles)(ServiceTool);
