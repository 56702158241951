export const EXAMPLE_ACTION = 'EXAMPLE_ACTION';
export const setExampleAction = (data) => ({
    type: EXAMPLE_ACTION,
    payload: data
});

export const SET_ACCESSIBLE_GROUPS = 'SET_ACCESSIBLE_GROUPS';
export const setAccessibleGroups = (data) => ({
    type: SET_ACCESSIBLE_GROUPS,
    payload: data
});