import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { LocalizeProvider } from 'react-localize-redux';
import { Store } from './data/store';
import App from './App';

const container = document.getElementById('root');
const root = createRoot(container);

const renderApp = () =>
    root.render(
        <Provider store={Store}>
            <LocalizeProvider>
                <App />
            </LocalizeProvider>
        </Provider>
    );

if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./App', renderApp);
}

renderApp();
