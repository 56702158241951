import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { setCommonDialogAction } from '../actions/actions';

var styles = () => ({
    robotPeekaboo: {
        position: 'fixed',
        bottom: 105,
        right: 'calc(50% + 18px)',
        animation: '$head 240s ease-in-out infinite'
    },
    robot: {
        '& #head': {
            position: 'absolute',
            width: 36,
            height: 40,
            top: 55,
            left: 9,
            zIndex: 5
        },
        '& #head div': {
            position: 'absolute'
        },
        '& #h2': {
            width: '100%',
            height: 8,
            bottom: -4,
            left: 1,
            background: '#A7C6DB',
            border: '1px solid #000',
            borderRadius: '100%',
            zIndex: 0
        },
        '& #h1': {
            width: '100%',
            height: 46,
            bottom: 0,
            left: 1,
            background: '#A7C6DB',
            borderLeft: '1px solid #000',
            borderRight: '1px solid #000',
            zIndex: 1
        },
        '& #h3': {
            width: '100%',
            height: 38,
            bottom: '62%',
            left: 1,
            background: '#A7C6DB',
            border: '1px solid #000',
            borderRadius: '100%',
            zIndex: 0
        },
        '& #h4': {
            height: 10,
            width: 12,
            bottom: 56,
            border: '1px solid #000',
            background: '#C4E0EE',
            zIndex: -1,
            borderRadius: '100%',
            left: 13
        },
        '& #h5': {
            bottom: 64,
            background: '#76A3C4',
            border: '1px solid #000',
            width: 2,
            height: 20,
            left: 18,
            zIndex: -2
        },
        '& #h5 span': {
            position: 'absolute',
            left: -3,
            top: -5,
            height: 6,
            width: 6,
            background: '#CEE9FA',
            border: '1px solid #000',
            borderRadius: '100%'
        },
        '& #eyes': {
            width: 49,
            height: 22,
            background: '#CAE1F1',
            border: '1px solid #000',
            borderRadius: 10,
            zIndex: 5,
            left: -5,
            top: -4
        },
        '& #e': {
            top: 2,
            left: 2,
            right: 2,
            bottom: 2,
            background: '#231F20',
            borderRadius: 10,
            overflow: 'hidden'
        },
        '& #e1': {
            height: 14,
            width: 16,
            background: '#FFF',
            borderRadius: '100%',
            top: 2,
            left: 4
        },
        '& #e2': {
            height: 14,
            width: 16,
            background: '#FFF',
            borderRadius: '100%',
            top: 2,
            right: 4
        },
        '& #eye': {
            height: 4,
            width: 4,
            borderRadius: '100%',
            background: '#231F20',
            top: 5,
            left: 2,
            animation: '$eye 5s ease-in-out infinite'
        },
        '& #e1 span': {
            position: 'absolute',
            width: '100%',
            height: 4,
            background: '#231F20',
            animation: '$eye1 8s ease-in-out infinite'
        },
        '& #e2 span': {
            position: 'absolute',
            width: '100%',
            height: 4,
            background: '#231F20',
            animation: '$eye2 8s ease-in-out infinite'
        },
        '& #mouth': {
            top: 23,
            left: 3,
            height: 14,
            width: 32,
            background: '#FFF',
            border: '1px solid #000',
            zIndex: 5,
            borderRadius: 7,
            overflow: 'hidden'
        },
        '& #mouth span': {
            display: 'block',
            float: 'left',
            width: 1,
            height: 14,
            background: '#150B13',
            marginLeft: 5
        },
        '& #mouth span:first-child': {
            marginLeft: 3
        },
        '& #m1': {
            top: 9,
            width: '100%',
            height: 20,
            borderTop: '1px solid #000',
            borderRadius: '100%',
            animation: '$m1 4s ease-in-out infinite'
        },
        '& #m2': {
            bottom: 8,
            width: '100%',
            height: 20,
            borderBottom: '1px solid #000',
            borderRadius: '100%',
            animation: '$m1 4s ease-in-out infinite'
        }
    },
    '@keyframes eye': {
        from: { top: 5, left: 2 },
        '10%': { top: 5, left: 10 },
        '20%': { top: 5, left: 2 },
        '30%': { top: 5, left: 2 },
        '40%': { top: 9, left: 9 },
        '45%': { top: 5, left: 6 },
        '60%': { top: 5, left: 6 },
        '70%': { top: 3, left: 2 },
        '80%': { top: 8, left: 2 },
        '90%': { top: 7, left: 10 },
        to: { top: 5, left: 2 }
    },
    '@keyframes eye1': {
        from: { height: 1, transform: 'rotateZ(0deg)', left: 0, top: 0 },
        '10%': { height: 4, transform: 'rotateZ(0deg)', left: 0, top: 0 },
        '20%': { height: 2, transform: 'rotateZ(0deg)', left: 0, top: -1 },
        '30%': { height: 5, transform: 'rotateZ(30deg)', left: 5, top: -1 },
        '40%': { height: 7, transform: 'rotateZ(30deg)', left: 5, top: -1 },
        '50%': { height: 4, transform: 'rotateZ(0deg)', left: 0, top: -1 },
        '70%': { height: 0, transform: 'rotateZ(0deg)', left: 0, top: -1 },
        '80%': { height: 8, transform: 'rotateZ(30deg)', left: 5, top: -2 },
        '90%': { height: 5, transform: 'rotateZ(0deg)', left: 0, top: -1 },
        to: { height: 1, transform: 'rotateZ(0deg)', left: 0, top: 0 }
    },
    '@keyframes eye2': {
        from: { height: 1, transform: 'rotateZ(0deg)', right: 0, top: 0 },
        '10%': { height: 4, transform: 'rotateZ(0deg)', right: 0, top: 0 },
        '20%': { height: 2, transform: 'rotateZ(0deg)', right: 0, top: -1 },
        '30%': { height: 5, transform: 'rotateZ(-30deg)', right: 5, top: -1 },
        '40%': { height: 7, transform: 'rotateZ(-30deg)', right: 5, top: -1 },
        '50%': { height: 4, transform: 'rotateZ(0deg)', right: 0, top: -1 },
        '70%': { height: 0, transform: 'rotateZ(0deg)', right: 0, top: -1 },
        '80%': { height: 8, transform: 'rotateZ(-30deg)', right: 5, top: -2 },
        '90%': { height: 5, transform: 'rotateZ(0deg)', right: 0, top: -1 },
        to: { height: 1, transform: 'rotateZ(0deg)', right: 0, top: 0 }
    },
    '@keyframes m1': {
        from: { borderRadius: '0%', width: '100%', left: 0 },
        '25%': { borderRadius: '40%', width: '200%', left: 0 },
        '50%': { borderRadius: '0%', width: '100%', left: 0 },
        '75%': { borderRadius: '100%', width: '200%', left: '-50%' },
        to: { borderRadius: '0%', width: '100%', left: 0 }
    },
    '@keyframes head': {
        from: { bottom: 0 },
        '92%': { bottom: 0 },
        '93%': { bottom: 75 },
        '94%': { bottom: 0 },
        '95%': { bottom: 105 },
        '99%': { bottom: 105 },
        to: { bottom: 0 }
    }
});

class Robot extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            dialogOpen: false
        };
    }

    handleClick = () => {
        if (!this.props.commonDialog.show) {
            this.props.setCommonDialogAction('Friendly Robot Says:', 'Just pick something already!', true);
        }
    };

    render() {
        var { peekaboo, classes } = this.props;

        return (
            <div className={`${classes.robot} ${peekaboo ? classes.robotPeekaboo : ''}`}>
                <div id="head" onClick={this.handleClick}>
                    <div id="h1"></div>
                    <div id="h2"></div>
                    <div id="h3"></div>
                    <div id="h4"></div>
                    <div id="h5">
                        <span></span>
                    </div>
                    <div id="eyes">
                        <div id="e">
                            <div id="e1">
                                <div id="eye"></div>
                                <span></span>
                            </div>
                            <div id="e2">
                                <div id="eye"></div>
                                <span></span>
                            </div>
                        </div>
                    </div>
                    <div id="mouth">
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <span></span>
                        <div id="m1"></div>
                        <div id="m2"></div>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStateToProps(state) {
    return {
        commonDialog: state.rootReducer.commonDialog
    };
}

const mapDispatchToProps = (dispatch) => ({
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors))
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(Robot));
