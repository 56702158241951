import { EXAMPLE_ACTION, SET_ACCESSIBLE_GROUPS } from '../actions/RequestsActions';

const initialState = {
    accessibleGroupsData: {},
    accessibleGroupsOptions: []
};

const RequestsReducer = (state = initialState, action) => {
    switch (action.type) {
        case EXAMPLE_ACTION:
            return Object.assign({}, state, {
                example: action.payload
            });
        case SET_ACCESSIBLE_GROUPS: 
            return Object.assign({}, state, {
                accessibleGroupsData: action.payload.accessibleGroups,
                accessibleGroupsOptions: action.payload.accessibleGroups.map((ag)=> {
                    if(ag.isCognito) {
                        return { label: ag.groupName, value: ag.groupName, ...ag };
                    } else {
                        return { label: ag.groupName, value: ag.groupId, ...ag };
                    }
                }),
            });
        default:
            return state;
    }
};

export default RequestsReducer;
