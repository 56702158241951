import React, { lazy, Suspense } from 'react';
import { connect } from 'react-redux';
import Snackbar from '@material-ui/core/Snackbar';
import { withStyles } from '@material-ui/core/styles';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { Security } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import { withLocalize } from 'react-localize-redux';
import { renderToStaticMarkup } from 'react-dom/server';
import {
    DrcThemeProvider,
    DrcThemeUtilities,
    DrcSecureHeader,
    DrcSecureGroupRoute,
    DrcImage,
    DrcPageNotFound,
    DrcDialog,
    DrcButton,
    DrcEnvironmentMarker,
    DrcMain,
    DrcPanel,
    DrcMediaQueries,
    Helmet,
    DrcVersionInfo,
    DrcImplicitCallback
} from '@driscollsinc/driscolls-react-components';
import { DuThemeUtilities, DuCommonTranslations } from '@driscollsinc/driscolls-react-utilities';
import { Middleware } from '@driscollsinc/one-ring';
import {
    hideCommonDialogAction,
    showLoadingScreenAction,
    hideLoadingScreenAction,
    addInfo,
    setCommonDialogAction,
    showToast
} from './actions/actions';
import { setInitializeRedirectUrl, setMasterDataInitialized } from './actions/MasterActions';
import MasterDataUtilities from './data/MasterDataUtilities';
import LoggingUtilities from './data/LoggingUtilities';
import HeaderButtons from './components/HeaderButtons';
import LogOut from './pages/LogOut';
import InitializeApplication from './pages/InitializeApplication';
import ServiceToolsPage from './pages/ServiceToolsPage';
import UrlShortener from './pages/UrlShortener';
import BerrySurvey from './pages/BerrySurvey';
import BusLoading from './components/BusLoading';
import BusLoadingPage from './components/BusLoadingPage';
import APP_TRANSLATIONS from './data/appTranslations';
import { createBrowserHistory } from 'history';
import LogoPng from './Images/Logo_140_Transparent_White.png';
import LogoWebP from './Images/Logo_140_Transparent_White.webp';
import BackgroundPng from './Images/Bowl_Mixed_3.png';
import BackgroundWebP from './Images/Bowl_Mixed_3.webp';

const DrcPageLogin = lazy(() => import('@driscollsinc/driscolls-react-components/dist/Pages/DrcPageLogin'));

const Releases = lazy(() => import('./pages/Releases'));
const HealthCheck = lazy(() => import('./pages/HealthCheck'));

const UserManagementHome = lazy(() => import('./tools/UserManagement/pages/UserManagementHome'));
const FolderManagementHome = lazy(() => import('./tools/FolderManagement/pages/FolderManagementHome'));
const ReleaseManagementHome = lazy(() => import('./tools/ReleaseNotes/pages/ReleaseManagementHome'));
const StyleGuideHome = lazy(() => import('./tools/StyleGuide/pages/StyleGuideHome'));
const DeveloperLearningHome = lazy(() => import('./tools/DeveloperLearning/pages/DeveloperLearningHome'));
const DocumentCreationHome = lazy(() => import('./tools/DocumentCreation/pages/DocumentCreationHome'));
const ResourcesHome = lazy(() => import('./tools/Resources/pages/ResourcesHome'));
const ResourceAllocationHome = lazy(() => import('./tools/ResourceAllocation/pages/ResourceAllocationHome'));
const UrlShortenerHome = lazy(() => import('./tools/UrlShortener/pages/UrlShortenerHome'));
const TriggersHome = lazy(() => import('./tools/Triggers/pages/TriggersHome'));
const DisastersHome = lazy(() => import('./tools/Disasters/pages/DisastersHome'));
const DaxSupport = lazy(() => import('./tools/DaxSupport/pages/DaxSupport'));

const CognitoLogin = lazy(() => import('./pages/CognitoLogin'));

const adminGroups = window.config.OKTA_ADMIN_GROUPS || [];
const allGroups = window.config.OKTA_ALL || [];
const DEV_GROUPS = window.config.OKTA_DEV || [];
const DOC_CREATION_GROUPS = window.config.OKTA_DOCUMENT_CREATION || [];
const GS_GROUPS = window.config.OKTA_GS_ADMIN || []; //Grower Settlement Trigger Page
const DISASTER_GROUPS = window.config.OKTA_DISASTER_ADMIN || [];
const DEV_ADMINS = window.config.OKTA_DEV_ADMIN || [];

const SITE_NAME = 'Business Utilities Services';
const DARK_BACKGROUND_BRIGHTNESS = 20;
const DARK_BACKGROUND = DuThemeUtilities.SetHslBrightness(DuThemeUtilities.ConvertHexToHsl(DuThemeUtilities.DefaultColors.primary.green), DARK_BACKGROUND_BRIGHTNESS);

var styles = (theme) => ({
    '@global': {
        /* width */
        '::-webkit-scrollbar': {
            width: '10px',
            height: '10px'
        },

        /* Track */
        '::-webkit-scrollbar-track': {
            boxShadow: 'inset 0 0 5px grey',
            borderRadius: '5px'
        },

        /* Handle */
        '::-webkit-scrollbar-thumb': {
            backgroundColor: theme.palette.primary.light,
            borderRadius: '5px'
        },
        '.MuiButton-root': {
            fontSize: '1rem',
            boxShadow: 'none'
        }
    },
    logo: {
        height: 40,
        ['@media ' + DrcMediaQueries.mobileL]: {
            display: 'none'
        }
    },
    logoShowAlways: {
        height: 40
    },
    header: {
        background: 'transparent',
        backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
        '& .MuiToolbar-root': {
            padding: '0px 12px'
        },
        '& .titleContainer': {
            marginLeft: 20
        },
        '& .titleContainer h1': {
            display: 'contents'
        },
        '& .title': {
            textShadow: 'none',
            fontSize: '1.5rem',
            lineHeight: '40px',
            color: DuThemeUtilities.DefaultColors.text.white.main
        },
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: DARK_BACKGROUND,
            '& .menuButton': {
                border: '1px solid hsla(341, 57%, 90%, 1)'
            },
            '& .menuButton svg': {
                color: 'hsla(341, 57%, 90%, 1)'
            }
        },
        '@media print': {
            display: 'none'
        }
    },
    loaderWithMessage: {
        width: '644px',
        left: 'calc(50vw - 322px)',
        top: 'calc(50vh - 60px)',
        position: 'fixed',
        padding: '20px',
        backgroundColor: '#000',
        borderRadius: '10px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: '#ddd'
        }
    },
    successToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.green,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    },
    errorToast: {
        '& .MuiSnackbarContent-root': {
            backgroundColor: DuThemeUtilities.DefaultColors.primary.red,
            color: '#fff',
            fontSize: '14px',
            textAlign: 'center'
        },
        '& .MuiSnackbarContent-message': {
            width: '100%'
        }
    }
});

class App extends React.Component {
    constructor(props) {
        super(props);
        this.history = createBrowserHistory();

        this.oktaAuth = new OktaAuth({
            issuer: window.config.OKTA_ISSUER,
            clientId: window.config.OKTA_CLIENT_ID,
            redirectUri: window.location.origin + '/implicit/callback',
            onAuthRequired: () => {
                this.history.push('/');
            },
            auto_renew: true,
            scopes: ['openid', 'email', 'MulesoftAPIAccess']
        });

        this.oktaAuth.start();

        this.state = {
            theme: DrcThemeUtilities.CreateSimpleTheme(DuThemeUtilities.DefaultColors.primary.green)
        };

        //Initializing the Internationalization
        this.props.initialize({
            languages: DuCommonTranslations.LANGUAGES,
            translation: { ...DuCommonTranslations.COMMON_TRANSLATIONS, ...APP_TRANSLATIONS },
            options: { renderToStaticMarkup }
        });

        this.props.setInitializeRedirectUrl(window.location.pathname.length > 1 ? window.location.pathname : '/ServiceTools/');

        this.closeCommonDialog = this.closeCommonDialog.bind(this);

        MasterDataUtilities.Register(this.props.setInitializeRedirectUrl, this.props.setMasterDataInitialized);
        LoggingUtilities.Register(this.props.addInfo);
        Middleware.SetProps(this.props.showLoadingScreenAction, this.props.hideLoadingScreenAction, this.props.setCommonDialogAction, false);
        DuThemeUtilities.RegisterTheme(DuThemeUtilities.ButtonThemes.Polymorphism);
    }

    closeCommonDialog() {
        this.props.hidecommonDialog();
    }

    handleCloseNotification = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        this.props.showToast('', true);
    };

    restoreOriginalUri = async (_oktaAuth, originalUri) => {
        this.history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

    render() {
        var { classes } = this.props;

        const isSurvey = window.location.pathname.includes('/Survey/');
        const headerTitle = this.props.pageTitle.length > 0 ? this.props.pageTitle : SITE_NAME;
        let title = '';

        if (!isSurvey) {
            title = headerTitle;
        }

        return (
            <DrcThemeProvider theme={this.state.theme}>
                {isSurvey ? null : <DrcEnvironmentMarker />}
                <Helmet>
                    <title>{headerTitle}</title>
                </Helmet>
                <Router history={Router.browserHistory}>
                    <Security oktaAuth={this.oktaAuth} restoreOriginalUri={this.restoreOriginalUri}>
                        <DrcSecureHeader
                            title={title}
                            allLinks={[]}
                            fullWidth={true}
                            logo={
                                <Link to="/ServiceTools/">
                                    <DrcImage
                                        src={LogoPng}
                                        webp={LogoWebP}
                                        className={isSurvey ? classes.logoShowAlways : classes.logo}
                                        alt="Driscoll's Logo"
                                    />
                                </Link>
                            }
                            className={classes.header}
                        >
                            <HeaderButtons />
                        </DrcSecureHeader>
                        <Suspense
                            fallback={
                                <DrcMain transparent>
                                    <DrcPanel>
                                        <BusLoading />
                                    </DrcPanel>
                                </DrcMain>
                            }
                        >
                            <Switch>
                                <Route
                                    path="/"
                                    exact
                                    render={(props) => (
                                        <DrcPageLogin
                                            {...props}
                                            poly
                                            setPageTitle={this.props.setPageTitleAction}
                                            backgroundPng={BackgroundPng}
                                            backgroundWebP={BackgroundWebP}
                                        />
                                    )}
                                />
                                <DrcSecureGroupRoute
                                    path="/InitializeApplication/"
                                    exact
                                    component={InitializeApplication}
                                    groupsAllowed={allGroups}
                                />
                                <DrcSecureGroupRoute path="/ServiceTools/" exact component={ServiceToolsPage} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/UserManagement/" component={UserManagementHome} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/FolderManagement/" component={FolderManagementHome} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Release/" component={ReleaseManagementHome} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/StyleGuide/" component={StyleGuideHome} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/DeveloperLearning/" component={DeveloperLearningHome} groupsAllowed={DEV_GROUPS} />
                                <DrcSecureGroupRoute path="/DocumentCreation/" component={DocumentCreationHome} groupsAllowed={DOC_CREATION_GROUPS} />
                                <DrcSecureGroupRoute path="/Resources/" component={ResourcesHome} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/ResourceAllocations/" component={ResourceAllocationHome} groupsAllowed={DEV_ADMINS} />
                                <DrcSecureGroupRoute path="/HealthCheck/" exact component={HealthCheck} groupsAllowed={allGroups} />
                                <DrcSecureGroupRoute path="/Releases/" exact component={Releases} groupsAllowed={adminGroups} />
                                <DrcSecureGroupRoute path="/UrlShortener/" exact component={UrlShortenerHome} groupsAllowed={adminGroups} />
                                <DrcSecureGroupRoute path="/Triggers/" exact component={TriggersHome} groupsAllowed={GS_GROUPS} />
                                <DrcSecureGroupRoute path="/DisasterRecovery/" component={DisastersHome} groupsAllowed={DISASTER_GROUPS} />
                                <DrcSecureGroupRoute path="/DaxSupport/" exact component={DaxSupport} groupsAllowed={adminGroups} />
                                <Route path="/Url/:urlId/" exact component={UrlShortener} />
                                <Route path="/Survey/:berryId/" exact component={BerrySurvey} />
                                <Route path="/Cognito/Login/" exact component={CognitoLogin} />
                                <Route
                                    path="/implicit/callback"
                                    render={(props) => (
                                        <DrcImplicitCallback {...props} backgroundPng={BackgroundPng} backgroundWebP={BackgroundWebP} />
                                    )}
                                />
                                <Route path="/LogOut/" exact component={LogOut} />
                                <Route component={DrcPageNotFound} />
                            </Switch>
                        </Suspense>
                    </Security>
                    {isSurvey ? null : <DrcVersionInfo allowClick={this.props.isInitialized} />}
                    <BusLoadingPage show={this.props.showLoadingScreen} text={this.props.loadingScreenMessage} />
                    <DrcDialog
                        isError={this.props.commonDialog.isMessage}
                        title={this.props.commonDialog.title}
                        open={this.props.commonDialog.show}
                        buttons={
                            <DrcButton poly line isError onClick={this.closeCommonDialog}>
                                OK
                            </DrcButton>
                        }
                    >
                        {this.props.commonDialog.content}
                    </DrcDialog>
                </Router>
                {this.props.toastMessage && (
                    <Snackbar
                        anchorOrigin={{
                            vertical: 'top',
                            horizontal: 'center'
                        }}
                        className={this.props.isSuccess ? classes.successToast : classes.errorToast}
                        open={this.props.toastMessage !== ''}
                        autoHideDuration={3000}
                        onClose={this.handleCloseNotification}
                        message={<span id="message-id">{this.props.toastMessage}</span>}
                    />
                )}
            </DrcThemeProvider>
        );
    }
}

function mapStateToProps(state) {
    return {
        showLoadingScreen: state.rootReducer.showLoadingScreen,
        loadingScreenMessage: state.rootReducer.loadingScreenMessage,
        commonDialog: state.rootReducer.commonDialog,
        pageTitle: state.rootReducer.pageTitle,
        isInitialized: state.masterReducer.isInitialized,
        toastMessage: state.rootReducer.toastMessage,
        isSuccess: state.rootReducer.isSuccess
    };
}

const mapDispatchToProps = (dispatch) => ({
    hidecommonDialog: () => dispatch(hideCommonDialogAction()),
    showLoadingScreenAction: () => dispatch(showLoadingScreenAction()),
    hideLoadingScreenAction: () => dispatch(hideLoadingScreenAction()),
    addInfo: (info) => dispatch(addInfo(info)),
    setMasterDataInitialized: (isInitialized) => dispatch(setMasterDataInitialized(isInitialized)),
    setInitializeRedirectUrl: (redirectUrl) => dispatch(setInitializeRedirectUrl(redirectUrl)),
    setCommonDialogAction: (title, errors) => dispatch(setCommonDialogAction(title, errors)),
    showToast: (message, isSuccess) => dispatch(showToast(message, isSuccess))
});

export default connect(mapStateToProps, mapDispatchToProps)(withLocalize(withStyles(styles)(App)));
