import { SET_FOLDER_DATA,SET_APPLICATION_DATA, SET_RELEASEDATATYPES_DATA, SET_RELEASEPACKAGES_DATA, SET_SELECTEDAPPLICATIONS_DATA, SET_SELECTEDPACKAGENOTES_DATA, SET_SELECTEDRELEASENOTE_DATA, SET_WORKITEMS_DATA, SET_TASKITEMS_DATA, SET_RELEASENOTEDATA_DATA, SET_ENVIRONMENTDELIVERABLES_DATA, SET_FILE_TYPES } from '../actions/ReleaseNotesActions';

const initialState = {
    applications:  [],
    applicationOptions: {},
    releasePackages: {},
    selectedApplications: [],
    selectedReleaseNotes: [],
    packageNotes: [],
    workItems: [],
    taskItems: [],
    environmentDeliverables: [],
    releaseNoteData: [],
    releaseDataTypes: [],
    folders: {},
    folderOptions: [],
    bucketNameOptions: [],
    fileTypes: {},
    fileTypeOptions: [],
    mimeTypeMapping: {},
};


function MapApplicationOptions(applications)
{
    const applicationOptions = applications.map((applicationItem) => {
        return { label: applicationItem.applicationName, value: applicationItem.applicationId };
    });
    return applicationOptions;
}

function MapReleasePackagesOptions(releasePackages)
{
    const applicationOptions = releasePackages.map((releasePackageItem) => {
        return { label: releasePackageItem.releasePackageTitle, value: releasePackageItem.releasePackageId };
    });
    return applicationOptions;
}

const ReleaseManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FOLDER_DATA:
            var folders = action.payload;
            var tempBucketNameOptions = [];
            var bucketNamesAdded = [];
            folders.results = folders.results.sort((a, b) => {
                if (a.folderName < b.folderName) {
                    return -1;
                }

                if (a.folderName > b.folderName) {
                    return 1;
                }

                return 0;
            });

            const folderOptions = folders.results.map((folderItem) => {
                if (!bucketNamesAdded.includes(folderItem.bucketName)) {
                    tempBucketNameOptions.push({ label: folderItem.bucketName, value: folderItem.bucketName });
                    bucketNamesAdded.push(folderItem.bucketName);
                }
                return { label: folderItem.folderName, value: folderItem.folderId, bucketName: folderItem.bucketName };
            });

            return Object.assign({}, state, {
                folders,
                folderOptions,
                bucketNameOptions: tempBucketNameOptions
            });
        case SET_FILE_TYPES:
            var fileTypes = action.payload;
            var mimeTypeMapping = {};
            fileTypes.results = fileTypes.results.sort((a, b) => {
                if (a.fileExtension < b.fileExtension) {
                    return -1;
                }

                if (a.fileExtension > b.fileExtension) {
                    return 1;
                }

                return 0;
            });

            const fileTypeOptions = fileTypes.results.map((fileType) => {
                mimeTypeMapping[fileType.fileExtension] = fileType.fileMimeType;
                return { label: fileType.fileExtension, value: fileType.fileTypeId };
            });

            return Object.assign({}, state, {
                fileTypes,
                fileTypeOptions,
                mimeTypeMapping
            });
        case SET_APPLICATION_DATA:
            var applications = action.payload || [];
            applications = applications.sort((a, b) => {
                if (a.applicationName < b.applicationName) {
                    return -1;
                }
                if (a.applicationName > b.applicationName) {
                    return 1;
                }
                return 0;
            });

            var applicationOptions = MapApplicationOptions(applications);

            return Object.assign({}, state, {
                applications,
                applicationOptions,
            });
        case SET_SELECTEDAPPLICATIONS_DATA:
            var selectedApplicationsItems = action.payload || [];
            return Object.assign({}, state, {
                selectedApplications: selectedApplicationsItems,
            });
        case SET_ENVIRONMENTDELIVERABLES_DATA:
            var environmentDeliverables = action.payload || [];
            return Object.assign({}, state, {
                environmentDeliverables: environmentDeliverables,
            });
        case SET_SELECTEDPACKAGENOTES_DATA:
            var packageNotesItems = action.payload || [];
            return Object.assign({}, state, {
                packageNotes: packageNotesItems,
            });
        case SET_WORKITEMS_DATA:
            var workItemsData = action.payload || [];
            return Object.assign({}, state, {
                workItems: workItemsData,
            });
        case SET_TASKITEMS_DATA:
            var taskItemsData = action.payload || [];
            return Object.assign({}, state, {
                taskItems: taskItemsData,
            });
        case SET_SELECTEDRELEASENOTE_DATA :
            var selectedReleaseNoteItems = action.payload || [];
            return Object.assign({}, state, {
                selectedReleaseNotes: selectedReleaseNoteItems,
            });
        case SET_RELEASENOTEDATA_DATA: 
            var releaseNoteData = action.payload || [];
            return Object.assign({}, state, {
                releaseNoteData: releaseNoteData,
            });
        case SET_RELEASEPACKAGES_DATA:
            var releasePackages = action.payload || [];

            var releasePackagesOptions = MapReleasePackagesOptions(releasePackages);
            return Object.assign({}, state, {
                releasePackages,
                releasePackagesOptions,
            });
        case SET_RELEASEDATATYPES_DATA:
            var releaseDataTypes = action.payload || [];
            return Object.assign({}, state, {
                releaseDataTypes: releaseDataTypes.sort((a, b) => (a.applicationDetailName > b.applicationDetailName) ? 1: -1 )
            });

        default:
            return state;
    }
};

export default ReleaseManagementReducer;
