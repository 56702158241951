export const SET_APPLICATION_DATA = 'SET_APPLICATION_DATA';
export const setApplicationData = (data) => ({
    type: SET_APPLICATION_DATA,
    payload: data
});

export const SET_RELEASEPACKAGES_DATA = 'SET_RELEASEPACKAGES_DATA';
export const setReleasePackageData = (data) => ({
    type: SET_RELEASEPACKAGES_DATA,
    payload: data
});

export const SET_FOLDER_DATA = 'SET_FOLDER_DATA';
export const setFolderData = (data) => ({
    type: SET_FOLDER_DATA,
    payload: data
});

export const SET_FILE_TYPES = 'SET_FILE_TYPES';
export const setFileTypes = (data) => ({
    type: SET_FILE_TYPES,
    payload: data
});

export const SET_RELEASEDATATYPES_DATA = 'SET_RELEASEDATATYPES_DATA';
export const setReleaseDataTypesData = (data) => ({
    type: SET_RELEASEDATATYPES_DATA,
    payload: data
});

export const SET_SELECTEDAPPLICATIONS_DATA ='SET_SELECTEDAPPLICATIONS_DATA';
export const setSelectedApplicationsData = (data) => ({
    type: SET_SELECTEDAPPLICATIONS_DATA,
    payload: data
});


export const SET_SELECTEDRELEASENOTE_DATA ='SET_SELECTEDRELEASENOTE_DATA';
export const setSelectedReleaseNoteData = (data) => ({
    type: SET_SELECTEDRELEASENOTE_DATA,
    payload: data
});

export const SET_SELECTEDPACKAGENOTES_DATA ='SET_SELECTEDPACKAGENOTE_DATA';
export const setPackageNoteData = (data) => ({
    type: SET_SELECTEDPACKAGENOTES_DATA,
    payload: data
});

export const SET_RELEASENOTEDATA_DATA ='SET_RELEASENOTEDATA DATA';
export const setReleaseNoteData = (data) => ({
    type: SET_RELEASENOTEDATA_DATA,
    payload: data
});

export const SET_WORKITEMS_DATA ='SET_WORKITEMS_DATA';
export const setWorkItemsData = (data) => ({
    type: SET_WORKITEMS_DATA,
    payload: data
});

export const SET_ENVIRONMENTDELIVERABLES_DATA ='SET_ENVIRONMENTDELIVERABLES_DATA';
export const setEnvironmentDeliverablesData = (data) => ({
    type: SET_ENVIRONMENTDELIVERABLES_DATA,
    payload: data
});

export const SET_TASKITEMS_DATA ='SET_TASKITEMS_DATA';
export const setTaskItemsData = (data) => ({
    type: SET_TASKITEMS_DATA,
    payload: data
});

