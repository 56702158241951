export const EXAMPLE_ACTION = 'EXAMPLE_ACTION';
export const setExampleAction = (data) => ({
    type: EXAMPLE_ACTION,
    payload: data
});

export const SET_SELECTED_APP = 'SET_SELECTED_APP';
export const setSelectedApp = (app) => ({
    type: SET_SELECTED_APP,
    payload: app
});

export const SET_SELECTED_POOL = 'SET_SELECTED_POOL';
export const setSelectedPool = (pool) => ({
    type: SET_SELECTED_POOL,
    payload: pool
});

export const SET_SELECTED_GROUP = 'SET_SELECTED_GROUP';
export const setSelectedGroup = (group) => ({
    type: SET_SELECTED_GROUP,
    payload: group
});

export const SET_APPLICATIONS = 'SET_APPLICATIONS';
export const setApplications = (data) => ({
    type: SET_APPLICATIONS,
    payload: data
});

export const ADD_APPLICATION = 'ADD_APPLICATION';
export const addApplication = (app) => ({
    type: ADD_APPLICATION,
    payload: app
});

export const SET_TRUSTED_ORIGINS = 'SET_TRUSTED_ORIGINS';
export const setTrustedOrigins = (data) => ({
    type: SET_TRUSTED_ORIGINS,
    payload: data
});

export const SET_GROUPS = 'SET_GROUPS';
export const setGroups = (group) => ({
    type: SET_GROUPS,
    payload: group
});

export const SET_USERS = 'SET_USERS';
export const setUsers = (users) => ({
    type: SET_USERS,
    payload: users
});

export const SET_APP_SEARCH_MAIN = 'SET_APP_SEARCH_MAIN';
export const setSearchedAppMain = (app) => ({
    type: SET_APP_SEARCH_MAIN,
    payload: app
});

export const SET_APP_SEARCH_DIALOG = 'SET_APP_SEARCH_DIALOG';
export const setSearchedAppDialog = (app) => ({
    type: SET_APP_SEARCH_DIALOG,
    payload: app
});

export const SET_GROUP_SEARCH = 'SET_GROUP_SEARCH';
export const setSearchedGroup = (group) => ({
    type: SET_GROUP_SEARCH,
    payload: group
});

export const SET_USER_SEARCH = 'SET_USER_SEARCH';
export const setSearchedUser = (user) => ({
    type: SET_USER_SEARCH,
    payload: user
});

export const SET_POOL_SEARCH = 'SET_POOL_SEARCH';
export const setSearchedPool = (pool) => ({
    type: SET_POOL_SEARCH,
    payload: pool
});

export const SET_GROUP_ACCESS = 'SET_GROUP_ACCESS';
export const setGroupAccess = (groupAccessData) => ({
    type: SET_GROUP_ACCESS,
    payload: groupAccessData
});

export const SET_USER_POOLS = 'SET_USER_POOLS';
export const setUserPools = (data) => ({
    type: SET_USER_POOLS,
    payload: data
});

export const SET_POOL_DATA = 'SET_POOL_DATA';
export const setPoolData = (data) => ({
    type: SET_POOL_DATA,
    payload: data
});

export const SET_PROFILE = 'SET_PROFILE';
export const setProfile = (profile) => ({
    type: SET_PROFILE,
    payload: profile
});

export const SET_USER_APPLICATIONS = 'SET_USER_APPLICATIONS';
export const setUserApplications = (data) => ({
    type: SET_USER_APPLICATIONS,
    payload: data
});

export const SET_GLOBAL_METADATA = 'SET_GLOBAL_METADATA';
export const setGlobalMetadata = (data) => ({
    type: SET_GLOBAL_METADATA,
    payload: data
});

export const SET_DATATYPES = 'SET_DATATYPES';
export const setDataTypes = (data) => ({
    type: SET_DATATYPES,
    payload: data
});


export const SET_GROUP_ALLOW = 'SET_GROUP_ALLOW';
export const setGroupAllow = (data) => ({
    type: SET_GROUP_ALLOW,
    payload: data
});
