import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import { withOktaAuth } from '@okta/okta-react';
import { withRouter } from 'react-router-dom';
import { DrcButton, DrcLanguageHeaderButton } from '@driscollsinc/driscolls-react-components';
import { DuThemeUtilities } from '@driscollsinc/driscolls-react-utilities';
import userRole from '../data/roleUtilities';
import DirectionsRun from '@material-ui/icons/DirectionsRun';

var styles = (theme) => ({
    role: {
        float: 'right',
        fontSize: 16,
        margin: 8,
        color: DuThemeUtilities.DefaultColors.text.white.main
    },
    headerButtons: {
        float: 'right',
        minWidth: 0,
        border: 'none !important',
        margin: 4,
        '& svg': {
            color: DuThemeUtilities.DefaultColors.text.white.main + ' !important',
            fontSize: 24
        }
    },
    overrideIcon: {
        '& svg': {
            color: DuThemeUtilities.DefaultColors.text.white.main + ' !important'
        }
    },
    popover: {
        '& .MuiPaper-elevation8': {
            maxWidth: 700,
            width: '100%'
        }
    },
    langPopover: {
        '& .MuiPaper-elevation8': {
            padding: '4px 12px',
            maxWidth: 400,
            width: '100%'
        }
    },
    language: {
        width: 120,
        float: 'right',
        height: 22
    },
    languageBtn: {
        width: '100%',
        margin: '8px 0'
    }
});

class HeaderButtons extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isLoggedIn: false,
            loggedInAs: '',
            anchorEl: null,
            anchorElLanguage: null
        };
    }

    componentDidUpdate() {
        this.getGroup();
    }

    changeLanguage = (lang) => {
        this.props.setActiveLanguage(lang.code);
        this.handleLanguageClose();
    };

    getGroup = async () => {
        let token = this.props.authState?.accessToken;

        if (!token || token.length <= 0) {
            if (this.state.loggedInAs !== '' || this.state.isLoggedIn) {
                this.setState({ loggedInAs: '', isLoggedIn: false });
            }

            return;
        }

        let name = userRole(token);

        if (this.state.loggedInAs !== name || !this.state.isLoggedIn) {
            this.setState({ loggedInAs: name, isLoggedIn: true });
        }
    };

    handleToolsClick = (event) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    handleToolsClose = () => {
        this.setState({ anchorEl: null });
    };

    handleLanguageClick = (event) => {
        this.setState({ anchorElLanguage: event.currentTarget });
    };

    handleLanguageClose = () => {
        this.setState({ anchorElLanguage: null });
    };

    render() {
        const { classes } = this.props;

        return (
            <React.Fragment>
                {this.state.isLoggedIn ? (
                    <React.Fragment>
                        <DrcButton className={classes.headerButtons} noStyle to="/LogOut/">
                            <DirectionsRun />
                        </DrcButton>
                    </React.Fragment>
                ) : null}
                <span className={classes.overrideIcon}>
                    <DrcLanguageHeaderButton />
                </span>
                <div className={classes.role}>{this.state.loggedInAs}</div>
            </React.Fragment>
        );
    }
}

export default withOktaAuth(withRouter(withStyles(styles)(HeaderButtons)));
