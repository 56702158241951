import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import BusLoading from './BusLoading';

const styles = (theme) => ({
    root: {
        position: 'fixed',
        top: 0,
        bottom: 0,
        left: 0,
        right: 0,
        color: theme.light.primary,
        backgroundColor: theme.light.transparent,
        display: 'none',
        zIndex: 1301,
        '@media (prefers-color-scheme: dark)': {
            color: theme.dark.primary,
            backgroundColor: theme.dark.transparent
        }
    },
    rootShow: {
        display: 'block'
    },
    loadingDiv: {
        width: 340,
        left: 'calc(50vw - 170px)',
        top: 'calc(50vh - 60px)',
        position: 'fixed',
        padding: '20px',
        backgroundColor: theme.light.variant.secondary,
        borderRadius: '10px',
        '@media (prefers-color-scheme: dark)': {
            backgroundColor: theme.dark.variant.secondary
        }
    },
    rootLoading: {
        textAlign: 'center'
    },
    hidden: {
        '& img': {
            visibility: 'hidden'
        }
    },
    '@keyframes bus-loading': {
        '0%': { transform: 'scale(0.7)' },
        '25%': { transform: 'scale(1)' },
        '50%': { transform: 'scale(0.7)' }
    }
});

class BusLoadingPage extends React.Component {
    render() {
        const { show, text, classes, hidden, className } = this.props;

        return (
            <div className={`${classes.root} ${show ? classes.rootShow : ''} ${className || ''}`}>
                <div className={classes.loadingDiv}>
                    <BusLoading text={text} hidden={hidden} isFloating />
                </div>
            </div>
        );
    }
}

export default withStyles(styles)(BusLoadingPage);
